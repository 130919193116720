<template>
    <div class="expired-to-sold">
        <PageTitle :title="title" />
        <template v-for="(item, i) in properties">
            <BeforeAfter :data="item.property" v-bind:key="'property'+i"/>
            <SingleTestimonial v-if="item.testimonial" :data="item.testimonial" v-bind:key="'testimonial'+i" />
        </template>
    </div>
</template>

<script>

import PageTitle from "../components/PageTitle"
import BeforeAfter from "../components/BeforeAfter"
import SingleTestimonial from "../components/SingleTestimonial"

export default {
    name: "ExpiredToSold",
    components: {
        PageTitle,
        BeforeAfter,
        SingleTestimonial
    },
    data(){
        return{
            title: "Expired To Sold",
            properties: [
                {
                    property: {
                        title: '986 Kewen Dr',
                        images: [
                            '/images/expiredtosold/Kewen/paul-salazar-kewen-1-before.jpg',
                            '/images/expiredtosold/Kewen/paul-salazar-kewen-1-after.jpg',
                            '/images/expiredtosold/Kewen/paul-salazar-kewen-2-before.jpg',
                            '/images/expiredtosold/Kewen/paul-salazar-kewen-2-after.jpg',
                            '/images/expiredtosold/Kewen/paul-salazar-kewen-3-before.jpg',
                            '/images/expiredtosold/Kewen/paul-salazar-kewen-3-after.jpg',
                            '/images/expiredtosold/Kewen/paul-salazar-kewen-4-before.jpg',
                            '/images/expiredtosold/Kewen/paul-salazar-kewen-4-after.jpg',
                            '/images/expiredtosold/Kewen/paul-salazar-kewen-5-before.jpg',
                            '/images/expiredtosold/Kewen/paul-salazar-kewen-5-after.jpg',
                            '/images/expiredtosold/Kewen/paul-salazar-kewen-6-before.jpg',
                            '/images/expiredtosold/Kewen/paul-salazar-kewen-6-after.jpg',
                        ],
                        before: [
                            '# of Agents before Paul Salazar: 3',
                            'Time on market before Paul Salazar: 193 days',
                            'Offers before Paul Salazar: 0',
                        ],
                        after: [
                            'Sold for 96% of list price',
                            'Property was featured in Forbes',
                            'Over 50 potential buyers through our COVID-safe open house',
                            'Painted ceilings and walls, removed window coverings, staged the property'
                        ],
                        link: '/property/986-Kewen-Dr'
                    }
                },
                {
                    property: {
                        title: '3457 White Rose Way',
                        images: [
                            '/images/expiredtosold/WhiteRose/paul-salazar-whiterose-1-before.jpg',
                            '/images/expiredtosold/WhiteRose/paul-salazar-whiterose-1-after.jpg',
                            '/images/expiredtosold/WhiteRose/paul-salazar-whiterose-2-before.jpg',
                            '/images/expiredtosold/WhiteRose/paul-salazar-whiterose-2-after.jpg',
                            '/images/expiredtosold/WhiteRose/paul-salazar-whiterose-3-before.jpg',
                            '/images/expiredtosold/WhiteRose/paul-salazar-whiterose-3-after.jpg',
                            '/images/expiredtosold/WhiteRose/paul-salazar-whiterose-4-before.jpg',
                            '/images/expiredtosold/WhiteRose/paul-salazar-whiterose-4-after.jpg',
                            '/images/expiredtosold/WhiteRose/paul-salazar-whiterose-5-before.jpg',
                            '/images/expiredtosold/WhiteRose/paul-salazar-whiterose-5-after.jpg',
                            '/images/expiredtosold/WhiteRose/paul-salazar-whiterose-6-before.jpg',
                            '/images/expiredtosold/WhiteRose/paul-salazar-whiterose-6-after.jpg',
                            '/images/expiredtosold/WhiteRose/paul-salazar-whiterose-7-before.jpg',
                            '/images/expiredtosold/WhiteRose/paul-salazar-whiterose-7-after.jpg',
                        ],
                        before: [
                            '# of Agents before Paul Salazar: 8',
                            'Time on market before Paul Salazar: 16 years',
                            'Offers before Paul Salazar: 0',
                        ],
                        after: [
                            'Sold for 99% of list price',
                            'Celebrity sale of property featured in Dirt.com article',
                            'Painted ceilings and walls, removed window coverings, staged the property'
                        ],
                        link: '/property/3457-White-Rose-Way'
                    }
                },
                {
                    property: {
                        title: '740 Howard St',
                        images: [
                            '/images/expiredtosold/Howard/before-1.jpg',
                            '/images/expiredtosold/Howard/after-1.jpg',
                            '/images/expiredtosold/Howard/before-2.jpg',
                            '/images/expiredtosold/Howard/after-2.jpg',
                            '/images/expiredtosold/Howard/before-3.jpg',
                            '/images/expiredtosold/Howard/after-3.jpg',
                        ],
                        before: [
                            '# of Agents before Paul Salazar: 4',
                            'Time on market before Paul Salazar: 177 days',
                            'Offers before Paul Salazar: 0',
                        ],
                        after: [
                            'Sold for 98% of list price',
                            'Rearranged furniture to make spaces feel larger',
                            'Photoshopped rooftop deck to expose potential value add'
                        ],
                        link: '/property/740-howard-st'
                    }
                },
                {
                    property: {
                        title: '2401 Jupiter Dr',
                        images: [
                            '/images/expiredtosold/Jupiter/paul-salazar-jupiter-1-before.jpg',
                            '/images/expiredtosold/Jupiter/paul-salazar-jupiter-1-after.jpg',
                            '/images/expiredtosold/Jupiter/paul-salazar-jupiter-2-before.jpg',
                            '/images/expiredtosold/Jupiter/paul-salazar-jupiter-2-after.jpg',
                            '/images/expiredtosold/Jupiter/paul-salazar-jupiter-3-before.jpg',
                            '/images/expiredtosold/Jupiter/paul-salazar-jupiter-3-after.jpg'
                        ],
                        before: [
                            '# of Agents before Paul Salazar: 1',
                            'Time on market before Paul Salazar: 119 days',
                            'Offers before Paul Salazar: 0',
                            'Exposed city views and painted blue interior walls to neutral palette',
                        ],
                        after: [
                            '3 offers received within 3 days',
                            'Sold in 3 days for 100% of list price',
                            'Over 20 potential buyers through our COVID-safe open house',

                        ],
                        link: '/property/2401-Jupiter-Dr'
                    },
                    testimonial: {
                        content: `"Before Paul took over, I had my house listed with another agent who didn’t sell the property. Paul and his team came in and made a few small changes, such as painting the blue baseboards and master bedrooms walls, which ended up transforming the home for the new potential buyers. We received multiple offers within the first few days and he sold it for full asking price. While we were in escrow, Paul still conducted showings in effort to get back-up offers. Throughout the transaction, Paul’s team was very helpful and easy to communicate with even though I was living on the east coast. I am very pleased with my experience working with the Paul Salazar Group."`,
                        author: '- Jeff M.'
                    }
                },
                {
                    property: {
                        title: '7825 Torreyson Dr',
                        images: [
                            '/images/expiredtosold/Torreyson/paul-salazar-torreyson-01-before.jpg',
                            '/images/expiredtosold/Torreyson/paul-salazar-torreyson-01-after.jpg',
                            '/images/expiredtosold/Torreyson/paul-salazar-torreyson-02-before.jpg',
                            '/images/expiredtosold/Torreyson/paul-salazar-torreyson-02-after.jpg',
                            '/images/expiredtosold/Torreyson/paul-salazar-torreyson-03-before.jpg',
                            '/images/expiredtosold/Torreyson/paul-salazar-torreyson-03-after.jpg'
                        ],
                        before: [
                            '# of Agents before Paul Salazar: 7',
                            '# of Offers before Paul Salazar: 0',
                            'Time on market before Paul Salazar: 3 years',

                        ],
                        after: [
                            'Received 2 offers within 20 days',
                            'Sold for 98% of list price',
                            'Over 150 potential buyers through our mega open houses',
                            'Contractor relationships led to $60k of seller savings in buyer requested repairs',
                        ],
                        link: '/property/7825-Torreyson-Dr'
                    },
                    testimonial: {
                        content: `"Paul took charge and successfully sold a completely renovated property in the Hollywood Hills which we had been trying to sell for over three years. He conceptualized a marketing plan, worked with us to insure that the property sold and took care of all of the small details that contribute to a successful sale. This was an investor owned property and Paul insured that there were service people available for cleaning, minor repairs and the like. He negotiated extensively with the buyer and concluded a sale that had eluded us for a long time. His service was outstanding, and we will use him for other properties that we develop."`,
                        author: '- Stewart P.'
                    }
                },
                {
                    property: {
                        title: '1180 Yocum St',
                        images: [
                            '/images/expiredtosold/Yocum/yocum-A1.jpg',
                            '/images/expiredtosold/Yocum/yocum-A2.jpg',
                            '/images/expiredtosold/Yocum/yocum-B1.jpg',
                            '/images/expiredtosold/Yocum/yocum-B2.jpg',
                            '/images/expiredtosold/Yocum/yocum-C1.jpg',
                            '/images/expiredtosold/Yocum/yocum-C2.jpg'
                        ],
                        before: [
                            '# of Agents before Paul Salazar: 2',
                            '# of Offers before Paul Salazar: 0',
                            'Time on market before Paul Salazar: 278 days'
                        ],
                        after: [
                            'Paul received 4 offers within 7 days',
                            'Sold for 98% of list price',
                            'Over 75 potential buyers through our mega open houses',
                            'Over 12 private showings in 2 weeks',
                            'Aggressive multiple offer strategy to increase sales price'
                        ],
                        link: '/property/1180-Yocum-St'
                    },
                    testimonial: {
                        content: `"We found Paul Salazar to be very responsive to our needs. His suggestions were sound, and his attitude towards all matters of the sale of our home was always very positive and polite. We're glad that we had Paul as our RE agent. We highly recommend him!"`,
                        author: '- Monica & Stewart B.'
                    },
                },
                {
                    property: {
                        title: '1905 N Beverly Dr',
                        images: [
                            '/images/expiredtosold/Beverly/beverly-A1.jpg',
                            '/images/expiredtosold/Beverly/beverly-A2.jpg',
                            '/images/expiredtosold/Beverly/beverly-B1.jpg',
                            '/images/expiredtosold/Beverly/beverly-B2.jpg',
                            '/images/expiredtosold/Beverly/beverly-C1.jpg',
                            '/images/expiredtosold/Beverly/beverly-C2.jpg'
                        ],
                        before: [
                            '# of Agents before Paul Salazar: 1',
                            '# of Offers before Paul Salazar: 0',
                            'Time on market before Paul Salazar: 3 months',
                        ],
                        after: [
                            'Paul Salazar received 2 offers',
                            'Sold for 95% of list price',
                            'Over 75 potential buyers through our mega open houses',
                            'Catered event with wine tasting',
                            'Aggressive multiple offer strategy to increase sales price',
                            'Leveraged initial offer to get second offer'
                        ],
                        link: '/property/1905-N-Beverly-Dr'
                    },
                    testimonial: {
                        content: `"I met Paul once my property came off the market after several months with no offers. He came in with a proactive strategy and advised me on what to do to get the house ready for market and attract the right buyer. Within 24 hours of meeting him he coordinated vendors to help with wallpaper removal, paint, and staging. He promised the house would look better but I really couldn't believe the final result. The video and photos speak for themselves! He did an amazing job transforming my home which lead to us getting multiple offers! I highly recommended him!"`,
                        author: '- Geraldine C.'
                    },
                },
                {
                    property: {
                        title: '4137 McConnell Blvd',
                        images: [
                            '/images/expiredtosold/McConnell/paul-salazar-mcconnell-1-before.jpg',
                            '/images/expiredtosold/McConnell/paul-salazar-mcconnell-1-after.jpg',
                        ],
                        before: [
                            '# of Agents before Paul Salazar: 1',
                            '# of Offers before Paul Salazar: 0',
                            'Time on market before Paul Salazar: 1 month',

                        ],
                        after: [
                            'Paul Salazar received 4 offers in 1 week',
                            'Sold in 11 days for 96% of list price',
                            'Aggressive multiple offer strategy to increase sales price',
                        ],
                        link: '/property/4137-McConnell-Blvd'
                    },
                    testimonial: {
                        content: `"We are very happy with our experience using Paul Salazar and his team to sell our house. We had our home on the market before with another agent who unfortunately could not sell it. Paul came in with a decisive plan and received an offer within the first week during the Christmas holiday. His invaluable experience with selling properties that were previously on the market was key. With his business-like approach, Paul created an Offering Memorandum (OM) which is typically only used for commercial properties, but it helped to illustrate the value in our property to all prospective buyers. He and Maggie kept great communication with us and were wonderful to work with. Looking forward to our next transaction with the Paul Salazar Group, thank you so much."`,
                        author: '- Arturo P.'
                    },
                },
                {
                    property: {
                        title: '951 Granville Ave #102',
                        images: [
                            '/images/expiredtosold/Granville/paul-salazar-granville-01-before.jpg',
                            '/images/expiredtosold/Granville/paul-salazar-granville-01-after.jpg',
                            '/images/expiredtosold/Granville/paul-salazar-granville-02-before.jpg',
                            '/images/expiredtosold/Granville/paul-salazar-granville-02-after.jpg',
                            '/images/expiredtosold/Granville/paul-salazar-granville-03-before.jpg',
                            '/images/expiredtosold/Granville/paul-salazar-granville-03-after.jpg'
                        ],
                        before: [
                            '# of Agents before Paul Salazar: 1',
                            '# of Offers before Paul Salazar: 0',
                            'Time on market before Paul Salazar: 50 days',

                        ],
                        after: [
                            'Paul Salazar received 2 offers within 5 days',
                            'Sold in 5 days for 100% of list price',
                            'Aggressive multiple offer strategy to increase sales price',
                            'Over 50 potential buyers through our COVID-safe open houses',
                            'Over 10 private showings in 1 week',
                        ],
                        link: '/property/951-Granville-Ave-102'
                    },
                    testimonial: {
                        content: `"Paul and his team were outstanding to work with. Paul is knowledgeable, professional and was a pleasure to work with. I highly recommend him and feel like I was lucky to have him and his associate Maggie Ecklund sell my three bedroom Brentwood condo during a tough Covid Market. Not only did I have a wonderful real estate experience but I feel like a gained a friend!"`,
                        author: '- Cindy G.'
                    },
                },
                {
                    property: {
                        title: '1415 Palisades Dr',
                        images: [
                            '/images/expiredtosold/Palisades/paul-salazar-palisades-01-before.jpg',
                            '/images/expiredtosold/Palisades/paul-salazar-palisades-01-after.jpg',
                            '/images/expiredtosold/Palisades/paul-salazar-palisades-02-before.jpg',
                            '/images/expiredtosold/Palisades/paul-salazar-palisades-02-after.jpg',
                            '/images/expiredtosold/Palisades/paul-salazar-palisades-03-before.jpg',
                            '/images/expiredtosold/Palisades/paul-salazar-palisades-03-after.jpg'
                        ],
                        before: [
                            '# of Agents before Paul Salazar: 1',
                            '# of Offers before Paul Salazar: 0',
                            'Time on market before Paul Salazar: 2 months',
                        ],
                        after: [
                            'Paul Salazar received 2 offers within 20 days',
                            'Sold for 98% of list price',
                            'Over 50 potential buyers through our mega open houses with catered wine tasting',
                            'Over 15 private showings in 2 weeks',
                            'Aggressive multiple offer strategy to increase sales price',
                            'Contractor relationships led to $6,500 of seller savings in buyer requested repairs'

                        ],
                        link: '/property/1415-Palisades-Dr'
                    },
                    testimonial: {
                        content: `"Our experience with Paul Salazar could not have been better. He did a fantastic job for us AND he is just a great person to work with. As soon as we interviewed him we could tell he was prepared, smart, personable and down to earth. He also had a plan that was unique and different from all the other realtors we interviewed. Paul was detailed and thoughtful every step of the way but was never pushy and always listened to us. You can tell he truly values and appreciates his clients and wants to do a great job for them. We highly recommend him!"`,
                        author: '- Mike & Natasha P.'
                    },
                }
            ],
        }
    },
    metaInfo: {
        title: 'Expired To Sold',
        titleTemplate: '%s | Paul Salazar',
        meta: [{
            name: 'description',
            content: "Paul Salazar"
        }]
    },
}
</script>

<style lang="scss" scoped>

</style>
